<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >   
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col lg="4" class="d-flex">
              <b-form-group label="Proyectos" label-for="project" class="mr-2 w-100">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="proyectos"
                  label="code"
                  input-id="project"
                  :reduce="(proyectos) => proyectos.id"
                  placeholder="Proyecto"
                  v-model="project_id"                
                  class="select-obra"
                  disabled
                >
                  <template v-slot:selected-option="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

			<b-col lg="4" class="d-flex">
              <b-form-group label="Usuarios" label-for="user" class="mr-2 w-100">
                <v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="users"
                    label="name"
                    input-id="user"
                    :reduce="(users) => users.email"
                    placeholder="Usuario"
                    v-model="user_email"
                    @input="getAlerts()"
					          :disabled="role_restriction=='admin' || role_restriction=='planner' ? false:true"
                  
                >
                  <template v-slot:selected-option="option">
					{{ option.name }} - {{ option.dni+ '' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.name }} - {{ option.dni+ '' }}
                  </template>
                </v-select>
              </b-form-group>
           	</b-col>	
			<b-col lg="3" class="d-flex">
              <b-form-group label="Tarea Programada" label-for="alert" class="mr-2 w-100">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="alerts"
                  label="name"
                  input-id="alert"
                  :reduce="(alerts) => alerts.id"
                  placeholder="Alerta"
                  v-model="alert_id"                
                  class="select-obra"  
                  @input="getQuerys()"                         
                >
                  <template v-slot:selected-option="option">
                    {{ option.name }} 
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.name }} 
                  </template>
                </v-select>
              </b-form-group>
            </b-col>			          
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>      
        <b-tabs fill>
          <!-- Render Tabs, supply a unique `key` to each tab -->
          <b-tab
            v-for="(item, index) in records"
            :key="'dyn-tab-' + (index + 1)"
            :title="item.name"
            :lazy="index + 1 != 1"
            @click="changeTab(item)"
          >
            <b-card no-body>
              <b-table
                class="position-relative"
                :hover="true"
                :items="restricciones"
                responsive
                :fields="visibleFields2"
                show-empty
                empty-text="No existen"
                @sort-changed="sortChanged2"
                no-border-collapse
                ref="selectableTable"
              >
                <template #cell(date_identified)="data">
                  <span>{{ validDate(data.item.date_identified) }}</span>
                </template>
                <template #cell(date_required)="data">
                  <span>{{ validDate(data.item.date_required) }}</span>
                </template>
                <template #cell(date_commited)="data">
                  <span>{{ validDate(data.item.date_commited) }}</span>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col sm="3">
                    <b-form-group
                      label-cols="4"
                      label-cols-md="4"
                      label-size="md"
                      label="Entradas"
                      label-for="input-md"
                    >
                      <b-form-select
                        v-model="showEntrie2"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="entries2"
                        @change="changeSizePage2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-75" style="font-size: 12px">
                    <span> {{ totalElements2 }} Registros en total</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage2"
                      :total-rows="totalElements2"
                      :per-page="showEntrie2"
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="cambioPagina2"
                      pills
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="15" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="15" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-tab>

          <!-- Render this if no tabs -->
          <template #empty>
            <div class="text-center text-muted">No hay datos que presentar</div>
          </template>
        </b-tabs>
      </b-card>    
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import ProjectsService from '@/services/ProjectsService'
import QueryService from '@/services/QueryService'
import ScheduleService from '@/services/ScheduleService'
import UserService from '@/services/UserService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { required } from '@validations'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import filters from './filters.vue'
const APIURL = process.env.APIURLFILE
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      restricciones: [],
	  alerts : [],
      querys:[],
      query_id:null,
	  alert_id:null,
      snowOption: {},
      status: '',
      statusFilter: '',
      required,
      show: false,
      apiurl: APIURL,
      showLoading: false,
      addEnd: false,
      isEdit: false,
      names_alerts: [],
      code: '',
      name: '', 
      fields_restric: [
        { key: 'row', label: '', sortable: false, visible: false },
        { key: 'code', label: 'Id-Restricción', sortable: false, visible: false },
        { key: 'description', label: 'Descripción', sortable: false, visible: true },
        { key: 'category.code', label: 'Categoria', sortable: false, visible: true },
        { key: 'sub_category.code', label: 'Sub-Categoria', sortable: false, visible: false },
        { key: 'priority', label: 'Prioridad', sortable: false, visible: true },
        { key: 'status', label: 'Estado', sortable: false, visible: true },
        { key: 'owner.name', label: 'Dueño', sortable: false, visible: true },
        { key: 'co_owner.name', label: 'Co-Dueño', sortable: false, visible: false },
        { key: 'type', label: 'Tipo', sortable: false, visible: false },
        { key: 'detail', label: 'Detalle', sortable: false, visible: false },
        { key: 'dependency', label: 'Dependencia', sortable: false, visible: false },
        { key: 'leadtime', label: 'LeadTime', sortable: false, visible: false },
        { key: 'date_identified', label: 'F. Identificada', sortable: false, visible: true },
        { key: 'date_required', label: 'F. Requerida', sortable: false, visible: true },
        { key: 'date_commited', label: 'F. Comprometida', sortable: false, visible: true },
        { key: 'project.code', label: 'Proyecto', sortable: false, visible: false },
        { key: 'activity.code', label: 'Actividad', sortable: false, visible: false },
        { key: 'location_1.code', label: 'Localidad 1', sortable: false, visible: false },
        { key: 'location_2.code', label: 'Localidad 2', sortable: false, visible: false },
        { key: 'location_3.code', label: 'Localidad 3', sortable: false, visible: false },
        { key: 'location_4.code', label: 'Localidad 4', sortable: false, visible: false }
      ], 
      showEntrie2: 10,
      entries2: [10, 20, 50, 100],
      totalAlertas: {
        vencidas: 0,
        por_vencer: 0
      },      
      project_id: JSON.parse(localStorage.getItem('project_id')),     
      records: [],     
      proyectos: [],     
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      totalElements2: 0,
      currentPage2: 1,
      id: 0,
      sort: 'id',
      order: 'desc',
      isAdd: false,
      sort2: 'id',
      order2: 'desc',
      userData: JSON.parse(localStorage.getItem('userData')),
	  role_restriction : JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
	  users:[],
	  user_email:null,
	  id_query_restric: null,
    }
  },
  components: {
    vSelect,
    quillEditor,
    flatPickr,
    filters,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    ValidationProvider,
    ValidationObserver,
    AppTimelineItem
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    visibleFields2() {
      return this.fields_restric.filter((field) => field.visible)
    }
  },
  mounted() { 
    this.getSelect()    
	this.getUsersByProject(this.project_id)
	
  },
  methods: {
	async changeTab(item) {
      this.restricciones = []
      this.id_query_restric = item.id
      this.currentPage2 = 1
      this.showEntrie2 = 10
      this.totalElements2 = 0
      this.getData()
    
    },
	async getUsersByProject(id) {
		
		const emailUser = this.userData.email
		const respUsers = await UserService.getUsersByProject(id, this.$store)
   
		if (respUsers.status) {
			this.users = respUsers.data.rows
			if(this.role_restriction!='admin'){
				this.user_email = emailUser		
				this.getAlerts()	
			}
			
		}
  },
    async getAlerts(){
		
		let arrayFilters = [];
		this.alerts = [];
		this.alert_id = null;
		this.restricciones = []
		this.records=[]
		arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: this.project_id })
		arrayFilters.push({ keyContains: 'users', key: 'string_contains', value: this.user_email})
		const url =`?limit=10000&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +JSON.stringify(arrayFilters)
		const resp = await ScheduleService.getSchedules(url, this.$store)
		if(resp.status){
			this.alerts = resp.data.rows
      if(this.alerts.length > 0)
      {
        this.alert_id = this.alerts[0].id
        this.getQuerys()
      }
		}
		console.log({respAlertas : resp})		
  },
    refreshView(){
      if(this.query_id){
        this.getData()
      }
    },
    cambioPagina2(e) {
      console.log('es este')
      this.currentPage2 = e
	  this.getData()
    },
    changeSizePage2() {
		this.getData()
    },
    sortChanged2(data) {
      this.sort2 = data.sortBy
      if (data.sortDesc) {
        this.order2 = 'desc'
      } else this.order2 = 'asc'
    },  
   
    //VALIDAR FECHA
    validDate(fecha) {
      fecha = new Date(fecha)
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },       
    async getQuerys() {      
		let arrayFilters = [];
		arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: this.project_id })
		arrayFilters.push({
			keyContains: 'query_alert.some',
			key: 'alert_id',
			value:  {in:this.alert_id}
		})

		const url =`?limit=10000&filter=` +JSON.stringify(arrayFilters)
		const resp = await QueryService.getQuerys(url, this.$store)
		console.log('respQuery', resp)
		if (resp.status) {
			this.querys = resp.data.rows
			if (resp.data.rows.length > 0) {
				const arrayRecord = resp.data.rows
				for (let index = 0; index < arrayRecord.length; index++) {
					const element = arrayRecord[index]
					element.filter = this.destructArray(element.filter)
				}
				if (resp.data.rows.length > 0) {
					this.records = arrayRecord
				
					this.id_query_restric = this.records[0].id
					this.getData()
					this.totalElements = resp.data.responseFilter.total_rows
				}
			}
		}
    },
	destructArray(objeto) {
		
		const obj = {}
		const filters = Array.isArray(objeto) ? objeto : JSON.parse(objeto)

		filters.forEach((element1, index) => {
		if (element1.keyContains != 'time') {
			if (element1.keyContains == 'status') {
			if (element1.value == 'proximos-a-vencer') {
				obj[`time`] = element1.time
			}
			obj[`status`] = element1.value
			} else {
			
			obj[`${element1.keyContains}`] = element1.keyContains == 'owner_id' ||  element1.keyContains == 'category_id' ||  element1.keyContains == 'speciality_id'  ? element1.value ? JSON.parse(element1.value) : '' : element1.value
			}
		}
		})
		objeto = obj     

		return objeto
	},    
    async getSelect() {
      const url2 = `?limit=100`
      const respProyectos = await ProjectsService.getProyectos(url2, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
      }
    },
	async getData() {
      const url =
        this.id_query_restric +
        `?limit=${this.showEntrie2}&page=${this.currentPage2}&order=${this.order2}&sort=${this.sort2}`
      this.showLoading = true
      console.log({urlGedata:url})
      const respConsult = await QueryService.getQueryRestriction(url, this.$store)
     
      if (respConsult.status) {
       
        this.restricciones = respConsult.data.rows
        this.totalElements2 = respConsult.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.pad-export {
  padding: 0.52rem 1rem !important;
}
.content_main {
  .content_timeline {
    max-height: 450px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .content_form {
    display: flex;
    justify-content: center;
  }
}
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
.mt-02 {
  margin-top: 0.2rem;
}
.gap-1 {
  gap: 0.5rem;
}
// @media screen and (min-width: 576px) {
//   .modal-dialog {
//     max-width: 90% !important;
//   }
// }
</style>
